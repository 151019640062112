import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M441.9 67.7c-47.5-68-141.4-88.2-209.3-45l-119.2 76c-32.6 20.5-55 53.8-61.8 91.6-5.7 31.6-.7 64.1 14.2 92.5-10.2 15.5-17.2 32.9-20.5 51.1-6.9 38.6 2.1 78.3 24.9 110.2 47.6 68.1 141.5 88.2 209.3 45l119.2-76c32.6-20.5 55-53.7 61.8-91.6 5.7-31.6.7-64.1-14.2-92.5 10.2-15.5 17.2-32.9 20.5-51.1 6.8-38.5-2.1-78.3-24.9-110.2"
        fill="#ff3e00"
      />
      <path
        d="M220.9 450.7c-38.4 10-79-5.1-101.7-37.7a87.845 87.845 0 01-15-66.3c.7-3.8 1.6-7.5 2.9-11.1l2.2-6.9 6.1 4.5c14.1 10.4 29.9 18.3 46.7 23.3l4.4 1.3-.4 4.4c-.5 6.3 1.2 12.6 4.8 17.7 6.8 9.8 19 14.4 30.6 11.4 2.6-.7 5.1-1.8 7.3-3.2l119.3-76c5.9-3.7 10-9.7 11.2-16.6 1.2-7-.4-14.2-4.5-20-6.8-9.8-19-14.4-30.6-11.4-2.6.7-5.1 1.8-7.3 3.2l-45.5 29c-7.5 4.8-15.7 8.3-24.2 10.6-38.4 10-79-5.1-101.7-37.7a87.845 87.845 0 01-15-66.3c4.1-22.8 17.6-42.8 37.2-55.1l119.3-76c7.5-4.8 15.7-8.4 24.2-10.6 38.4-10 79 5.1 101.7 37.7 13.7 19.2 19.1 43.1 15 66.3-.7 3.8-1.6 7.5-2.9 11.1l-2.2 6.8-6.1-4.5c-14.1-10.4-29.9-18.3-46.7-23.3l-4.4-1.3.4-4.4c.5-6.3-1.2-12.6-4.8-17.7-6.8-9.8-19-14.4-30.6-11.4-2.6.7-5.1 1.8-7.3 3.2l-119.3 76c-5.9 3.7-10 9.7-11.2 16.6-1.2 7 .4 14.2 4.5 20 6.8 9.8 19 14.4 30.6 11.4 2.6-.7 5.1-1.8 7.3-3.2l45.5-29c7.5-4.8 15.6-8.4 24.2-10.6 38.4-10 79 5.1 101.7 37.7 13.7 19.2 19.1 43.1 15 66.3-4.1 22.8-17.6 42.8-37.2 55.1l-119.3 76c-7.5 4.8-15.7 8.4-24.2 10.7"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
